* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  /* --primary-color: #05569f; */
  --bg-primary-color: #1c4b44;
  --primary-color: #47bba9;
  --red: #FF0000;
  --light-primary-color: #DAF1EE;
  --dark-primary-color: #153833;
  --light-color: #f7f7fd;
  --text-color: #495057;
  --secondary-color: #c6c6c6;
  --bg-primary-color: 71, 187, 169;
  --bg-primary200-color: #d3f4ec;
  --bg-primary-light: 71, 187, 169, 0.1 !important;
  --off-white: #FCFCFC;

  --secondary-color: #c6c6c6;
  --bg-secondary-color: rgba(196, 196, 196, 0.22);

  --royal-color: #2a27ae;
  --bg-royal-color: 42, 39, 174;
}

.font-onest {
  font-family: 'Onest', sans-serif !important;
}
.font-display {
  font-family: 'ClashDisplay-Variable', sans-serif !important;
}
.font-xmas{
  font-family: 'Christmas-Regular', sans-serif !important;

}
/* .font-display {
  font-family: 'Playfair Display', serif !important;
} */
.font-poppins {
  font-family: 'Poppins', sans-serif !important;
}

/* width */
.scrollbar-custom::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollbar-custom::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.bg-off-white{
background-color: var(--off-white);
}
/* Handle */
.scrollbar-custom::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}
.bg-black{
  background-color: #000
}
/* Handle on hover */
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notification-bar {
  top: -250px;
  left: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
  transition: top 300ms cubic-bezier(0.17, 0.04, 0.04, 0.94);
  box-sizing: border-box;
}

.chat-bubble-receiver {
  background-color: #b5c9ff4d;
  color: #1c1919;
  border-radius: 0px 20px 20px 20px;
}
.xmas-text{
  font-family: 'Christmas-Bold', sans-serif !important;
  color: #C93333;
  text-align: center;
  letter-spacing: 2px;
  font-size: 24px;
}
.whatsappBtn{
  background-color: #4F820C;
  /* width: 80%; */
  margin: auto ;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.hidden{
  display: none;
}
.chat-bubble-sender {
  background-color: #031850d6;
  color: #fff;
  /* color: black; */
  border-radius: 20px 0px 20px 20px;
}

.notification-bar.show {
  top: 0;
}

.animation-all-out {
  transition: all 5s ease-in-out;
}

.nav-theme-vertical.nav-pills .nav-link.active {
  background-color: transparent !important;
  /* color: #1073ce !important; */
  color: var(--primary-color) !important;
  /* border-left: 5px solid #1073ce !important; */
  border-left: 5px solid var(--primary-color) !important;
  font-weight: 600;
}

.nav-theme-horizontal.nav-pills .nav-link.active {
  background-color: transparent !important;
  /* color: #1073ce !important; */
  color: var(--primary-color) !important;
  /* border-bottom: 5px solid #1073ce !important; */
  border-bottom: 5px solid var(--primary-color) !important;
  border-radius: 0;
  font-weight: 600;
}

.map-btn {
  border-radius: 2rem;
}
.btn-tab-button {
  background-color: #f3f3f3;
  color: #000;
  border-left-color: #ccc;
}

.btn-tab-button:hover,
.btn-tab-button:focus,
.btn-tab-button:active {
  border-color: #f3f3f3 !important;
}

.rounded-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.rounded-bottom-left-0 {
  border-bottom-left-radius: 0;
}

.rounded-top-left-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-right-0 {
  border-top-right-radius: 0 !important;
}

.rounded-pill-end {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.rounded-pill-start {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}

.form-control-0,
.form-select-0 {
  font-size: 14px;
  border-radius: 12px;
}

.input-group-text-0 {
  border-radius: 12px;
  background-color: transparent;
}

.form-control-0:focus,
.form-select-0:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.search-inp:focus {
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.2) !important; */
  box-shadow: none !important;
  background: #fff !important;
  border: none !important;
}

.search-inp:active {
  background: var(--bg-primary-light) !important;
  background: #fff !important;
}

.border-0-last > div:last-child {
  border-bottom: none !important;
}    

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.bg-primary {
  background-color: rgba(
    var(--bg-primary-color),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-primary200 {
  background-color: var(--bg-primary200-color) !important;
}
.bg-primary-light {
  background-color: rgba(var(--bg-primary-light)) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}
.bg-primary-light{
  background-color:  var(--light-primary-color) !important;;
}
.textLight {
  color: var(--light-primary-color) !important;
}
.textDark {
  color: var(--dark-primary-color) !important;
}
.text-light-black {
  color: var(--text-color) !important;
}
.text-primary2 {
  color: var(--primary-color2) !important;
}

.text-purple {
  color: #f0effb;
}
.text-red {
  color: var(--red) !important;
}
.bg-red {
  background-color: var(--red) !important;
}
.text-gray {
  color: #333333;
}

.bg-purple {
  background-color: #f7f7fd;
}

.bg-custom-royal {
  background-color: rgba(
    var(--bg-royal-color),
    var(--bs-bg-opacity)
  ) !important;
}
.border-red{
  border:1px solid var(--red) !important;

}
.text-custom-royal {
  color: var(--royal-color);
}

.btn-custom-primary {
  color: #fff;
  background-color: var(--primary-color);
}
.btn-custom-secondary {
  color: #fff;
  background-color: var(--secondary-color);
}

.btn-custom-primary:hover {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.btn-cp {
  color: var(--primary-color);
  background-color: #fff;
}

.btn-cp:hover {
  color: #fff;
  border: 1px solid #fff;
}

.social-icon {
  height: 3rem;
  width: 3rem;
}
.social-icon2 {
  height: 2.3rem;
  width: 2.3rem;
}

.fs-80 {
  font-size: 80px;
}

.fs-60 {
  font-size: 60px;
}
.fs-48 {
  font-size: 48px;
}
.fs-32 {
  font-size: 32px;
}
.fs-30 {
  font-size: 30px;
}

.fs-24 {
  font-size: 24px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-10 {
  font-size: 11px;
}
.fs-8 {
  font-size: 8px;
}
.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}
.fs-28 {
  font-size: 28px;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600;
}

.br-12 {
  border-radius: 12px;
}
.underline{
  text-decoration: underline;
}
.underline-none{
  text-decoration:none !important
}
.pl-3 {
  padding-left: 1rem !important;
}
.lh-34 {
  line-height: 34px;
}
.lh-28 {
  line-height: 28px;
}

.bg-custom-secondary {
  background-color: var(--bg-secondary-color);
}
.bg-custom-secondary:hover {
  color: #000;
  border: 2px solid black !important;
}

.bg-custom-primary {
  background-color: var(--primary-color);
  color: #fff;
}
.w-max{
  width: max-content;
}
.btn-check:checked + .btn {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-search {
  margin: 0 auto;
  width: 50%;
  transition: all 0.5s ease-in-out;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: #fff;
  transition: all 0.5s ease-in-out;
}
.btn-search:hover {
  background: #fff;
  color: #000;
  border: 1px solid var(--primary-color);
}

.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  /* height: 55px; */
}

.btn-primary:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-outline {
  background-color: transparent;
  color: #000;
  border-radius: 5px;
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 55px;
  /* width: 75%; */
}
.btn-outline:hover {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}
.btn-outline-search {
  background-color: transparent;
  color: #000;
  border-radius: 5px;
  padding: 10px 10px;
  border: 1px solid #000;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  /* height: 55px; */
  width: 50%;
  transition: all 0.5s ease-in-out;
}
.btn-outline-search:hover {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}
.btn-outline-location {
  background-color: transparent;
  color: #f8f8f8;
  padding: 12px 40px;
  border: 2px solid #f8f8f8;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  height: 50px;
  width: 80%;
  margin-bottom: 32px;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}
.btn-outline-location:hover {
  background-color: var(--primary-color);
  color: black !important;
}

.btn:disabled {
  background-color: var(--primary-color);
}

.light-bg {
  background-color: var(--light-color);
}
.border-green{
  border: 1.7px solid  var(--primary-color);
}

.border-input {
  border: 1px solid red;
  /* border: 1px solid #99a4e1; */
}

.border-label {
  background-color: #99b6e130;
  border: 1px solid #0f0c3d !important;
}

.border-shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.border-round {
  border-radius: 12px;
}

.border-round-top {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.border-round-bottom-mini {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.border-round-bottom {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border-left-0 {
  border-left: none !important;
}

.border-purple {
  border: 1px solid #e0def7;
}
.border-round-xl {
  border-radius: 22px;
}

.border-round-lg {
  border-radius: 16px;
}

.border-round-sm {
  border-radius: 10px;
}

.border-round-xs {
  border-radius: 5px;
}
.rounded-full{
  border-radius: 100%;
}
.rounded-2{
  border-radius: 20px;
}
.image-upload {
  border: 2px solid #47BBA9;
  border-radius: 100%;
  width: fit-content;
  margin: auto;

}
.project-img-upload{
  border: 1px dashed #CCCCCC;
  border-radius: 10px;
  text-align: center;
  padding: 40px 0;
  margin: 20px 0;
}

.choose-file{
  color: #292929;
  text-decoration: none !important;
  background-color: #DAF1EE;
  border-radius: 5px; 
  padding: 5px;
}
.grid-4{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* .scrollBar {
  overflow-y: scroll;
  margin-right: 0.5px;
}

.scrollBar:hover,
.scrollBarHide:hover {
  overflow-y: scroll;
}

.scrollBar::-webkit-scrollbar,
.scrollBarHide::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}

.scrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #4d4d4d;
}

.scrollBarHide::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar {
  display: none;
}
.scrollBarHide::-webkit-scrollbar-thumb {
  background: #ffffff;
} */
.w-full{
  width: 100%;
}
.w-10
{
  width: 100px;
}
.w-150
{
  width: 130px;
}
.w-12{
  width: 1.3rem;
}
.w-15{
  width: 200px;
}
.w-20{
  width: 24rem;
}
.w-80{
  width: 80%;
}
.w-90{
  width: 90%;
}
.w-50{
  width: 50%;
}
.w-40{
  width: 40%;
}
.w-30{
  width: 30%;
}
.border-none {
  border: 0 !important;
}

.border-divider {
  border-right: 1px solid #e0def7;
}

.left-border-none {
  border-left: none !important;
  box-shadow: inset 0px 1px 0 rgba(0, 0, 0, 0.075);
}
.uppercase{
text-transform: uppercase;
}
.capitalize{
  text-transform: capitalize;
  }
.no-text-wrap{
  text-wrap: nowrap;
}
.light-text {
  color: #000929 !important;
  opacity: 0.5;
}

.px-30 {
  padding: 0 30px !important;
}
.p-12
{
  padding: 5rem 7rem
}
.p-20{
  padding: 5rem 15rem;
}
.pb-50 {
  padding-bottom: 50px;
}

.mb-12 {
  margin-bottom: 12px;
}
.mt-7 {
  margin-top: 7rem !important;
}
.ml-1 {
  margin-left: 8px;
}

.ml-2 {
  margin-left: 16px;
}

.ml-3 {
  margin-left: 24px;
}
.ml-5{
  margin-left: 10rem;
}

.mr-1 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 16px;
}

.mr-3 {
  margin-right: 24px;
}
.mr-4 {
  margin-right: 30px;
}
.mx-15 {
  margin: 0 15px;
}
.mx-auto {
  margin: 0 auto;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5{
  margin-bottom: 5rem;
}
.mt-neg {
  margin-top: -1.2rem;
}

.mb-neg {
  margin-bottom: -1.2rem;
}

.btn-el {
  background: transparent;
  border: none;
  cursor: pointer;
}

.form-control-custom {
  background: #fafafe;
  font-size: 14px;
}
.devsentmsg{
  background-color: #47BBA9;
  color: #fff;
  width: 300px;
  padding: 10px;
  border-radius: 10px 10px 0 10px;
  margin-left: auto;
}
.devrecmsg{
  background-color: #fff;
  color: #292929;
  width: 300px;
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 10px 10px 10px 0;

}

.form-control-custom:focus {
  border-color: #99a4e1;
  background: #fafafe;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.form-icon {
  border-color: #99a4e1;
  background: #fafafe;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.hr {
  border: 0.5px solid #00092976;
}
 .stepper-container {
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;

}

.step-item {
  display: flex;
  align-items: center;
  flex: 1;
  height: 40px;
}

.step-number {
  width: 4rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EEEEEE;
  color: #4b5563;
  font-weight: bold;
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.step-item.active .step-number {
  background-color: #DAF1EE;
  color: #292929;
}

.step-line {
  width: 100%; /* Make line take full remaining space */
  height: 10px;
  background-color: #DAF1EE;
  position: relative;
}
.step-line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #47BBA9;
  transition: width 0.3s ease;
}

.step-item.active .step-line::before {
  width: 100%;
}

.step-item:last-child {
  flex: 0 0 auto;
}


.stepper-container {
  --step-width: calc(100% / (var(--total-steps) - 1));
}
 /* .step-item:nth-child(-n + var(--current-step)) .step-line::before {
  width: 100%;
}

.step-item:nth-child(var(--current-step)) .step-line::before {
  width: calc((var(--current-step) - 1) * 100% / (var(--total-steps) - 1));
}   */
.slide-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 1000;
}

.slide-panel.open {
  transform: translateX(0);
}

.slide-panel-close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #4a5568;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: none;
}

.slide-panel-close:hover {
  color: #2d3748;
}

.slide-panel-content {
  padding: 24px;
  height: 100%;
  overflow-y: auto;
  width: 23rem;
}

.rental-tag {
  background-color: #e6f9ee;
  color: #27ae60;
  font-weight: bold;
  font-size: 14px;
  height: 35px;
  width: 80px;
  text-align: center;
}

.mb-children > *:not(:last-child) {
  margin-bottom: 10px;
}

.text-link {
  color: #2e48da;
  text-decoration: none;
  cursor: pointer;
}

.sidebar-link {
  color: #fff;
  text-decoration: none;
  padding: 10px 0px;
}

.sidebar-link.active {
  background-color: var(--primary-color);
}

.text-link-secondary {
  color: #404040;
  text-decoration: none;
}

.onboarding-bg {
  /* background: url('./assets/images/onboarding-bg-new.png'); */
  background: url('./assets/images/onbg4.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.bg-iBuy {
  background: url('./assets/images/bg-ibuy.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}
.h-40 {
 height: 40rem;
}
.h-70{
  height: 70vh !important;

}
.h-80{
  height: 80vh !important;

}
.max-h-60{
  max-height: 60vh !important;
}
/* .h-100{
  height: 100vh !important;
} */
.min-h-15 {
  min-height: 15rem;
}

::file-selector-button {
  display: none;
}

.h-card-color {
  color: #0e1956;
}

.h-modal-color {
  color: #13141c;
}
.card-img-top{min-height: 14rem;}
.invalid-otp {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.invalid-otp > .digits {
  border-color: #f75940;
}
.staycation-hero{
  width: 95%;
  margin: auto;
  border: 6px solid #c1a536;
  padding: 10px
}
.hero-head{
  padding: 10px;

}
.hero-head h4{
  font-size: 13px;
  white-space: nowrap;
}

.hero-head img{
  width: 70px;
  position: absolute;
  top: 0rem;
  left: -1.45rem;

}
.hero-content{
  width: 75%;
  margin: auto;
}
.staycation-bg{
  background-repeat: none;
  background-size: contain;
  height: 75vh;
}
.staycation-hero h1 {
  /* font-family: 'ClashDisplay-Variable' !important; */
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 39.36px;
  color: #C93333;
}
.staycation-hero h2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17.85px;
  color: #424242 !important;
}
.xmas-cap {
  top: -30px;
}

.campaign-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;

}

.campaign-text-content {
  background-color: #f2f2f2;
  padding: 1.5rem ;
  border-radius: 20px 20px 0 0 ;
  flex: 1; 
  width: 100%; 
}

.campaign-text-content h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.campaign-text-content p {
  color: #4a4a4a; 
}


.campaign-image-content {
  flex: 1;
}

.campaign-image-content img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: -10px;
}
.contact-container {
  backdrop-filter: blur(4px); 
  background-color: white;   
  padding-top: 1rem;          
  padding-bottom: 1rem;       
  margin: auto;
  width:95%;         
}
.slider-text{
  margin-top: 30rem;
}
.sc-hero-sec{
  height: 60vh;
  background-size: cover;
}
.contact-main{
  width: 100%;
  padding: 1.5rem;
  margin: auto;
}
.contact-main div h2{
  font-size: 32px;
}
.contact-main div h5{
  font-size: 24px;
}
.all-pkg{
  background: none;
 /* backdrop-filter: 'blur(10px)'; */
}
.ind-pkg{
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  padding: 30px 15px;
  border-radius: 24px;
  width: 90%;
  margin: auto;
  border-right: none;
}
@media screen and (min-width: 428px) {
  .sc-hero-sec{
    height: 42vh;
  }
 
}
@media screen and (min-width: 768px) {
  .all-pkg{
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 30px 15px;
    border-radius: 24px;
  }
  .ind-pkg{
    background: none;
    backdrop-filter: none;
    padding: 0;
    border-radius: 0;
    width: auto;
    margin: 0;
  }
  .ind-pkg:not(:last-child) {
    border-right: 1px solid #999999 ;
  }
  .contact-main{
    width: 80%;
  }
  .contact-main div h2{
    font-size: 48px;
  }
  .contact-main div h5{
    font-size: 32px;
  }
  .slider-text{
    margin-top: 0;
  }
  .contact-container {
    backdrop-filter: blur(4px); 
    background-color: white;   
    padding-top: 1rem;          
    padding-bottom: 1rem;       
    margin-top: 1rem;          
    margin-bottom: 1rem;       
    border-radius: 0.5rem;     
    margin-left: 6rem; 
    width:auto        
  }
  .staycation-hero{
    width: 65%;
    margin: auto;
  padding: 40px

  }
  .hero-head{
    padding: 20px;
  }
  .hero-head h4{
    font-size: 32px;
  }
  .hero-head img{
    /* margin-right: -3.4rem; */
    width: 185px;
    position: absolute;
    top: -1.4rem;
    left: -4rem;

  }
  .staycation-bg{
    background-repeat: none;
    background-size: cover;
    height: 100%;

  }
  .hero-content{
    width: 100%;
    margin-top: 50px;
  }
  .xmas-text{
    font-size: 44px;
    letter-spacing: 4px;

  }
  .staycation-hero h1 {
    font-style: normal;
    font-size: 55px !important;
    line-height: 70px;
    letter-spacing: 5px;
    font-weight: 900 !important;
  }
  .staycation-hero h2 {
    font-style: normal;
    font-weight: 800 !important;
    font-size: 24px !important;
    line-height: 40px;
    letter-spacing: 2px;
    width: 80%;
    margin: auto;

  }
  .xmas-cap {
    top: -20px;
  }
  .campaign-wrapper {
    flex-direction: row;
  }

  .campaign-text-content {
    background-color: #f2f2f2;
  padding: 1.5rem 3rem 1.5rem 1.5rem;
  border-radius: 20px 0 0 20px;
  flex: 1; 
  width: 100%; 
  margin-right: -20px; 
  }

  .campaign-image-content {
    max-width: 100%;
  }

  .campaign-text-content h5 {
    font-size: 1.3rem; 
    line-height: 1.5;
  }

  .campaign-text-content p {
    font-size: 1rem; 
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (min-width: 997px) {
  .w-form-responsive {
    width: 470px;
  }
}

@media (min-width: 998px) {
  .w-form-responsive {
    width: 410px;
  }
}

@media (min-width: 1200px) {
  .w-form-responsive {
    width: 500px;
  }
}

@media (max-width: 767px) {
  .w-form-special-responsive {
    width: 430px;
  }

  .btn-search {
    width: 100%;
    border-radius: 1rem;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

@media screen and (max-width: 512px) {
  .light-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .border-divider {
    border: 0;
  }
  .scrollable-range{
    overflow: scroll;
  }
}

@media screen and (max-width: 968px) {
  .border-divider {
    border: 0;
  }
}
/* Just to apply cursor */
.cursor-pointer {
  cursor: pointer;
}
.forumItem {
  transition: background-color 0.3s;
}
.forumItemactive{
background-color: #DAF1EE;

}
.forumItem:hover{
background-color: #DAF1EE;
}
.no-decoration {
  text-decoration: none;
}
.no-decoration-hover:hover {
  text-decoration: none;
}

.story-card:hover {
  transform: translateY(-5px);
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}
.overflow-scroll {
  overflow: scroll;

}
.side-bar {
  /* max-height: 100vh; */
  overflow: scroll;
}
/* @media (min-width: 768px) {
  .side-bar {
    bottom: 0;
  }
} */
@media (min-width: 992px) {
  .side-bar {
    bottom: 0;
    height: 100vh !important;
  }
}
select:focus,
textarea:focus,
input:focus {
  font-size: 16px !important;
}

.uploadPic {
  display: inline-block;
}
.delete-container {
  height: 15px;
  width: 15px;
  background: #fff;
  padding: 6px;
  border-radius: 50%;
  right: -6px;
  top: -6px;
  z-index: 9;
  display: grid;
  justify-content: center;
  /* align-items: center; */
  place-items: center;
}
.deletePic {
  transition: all 2s ease-in-out;

  line-height: 0;

  font-size: 14px;
}
/* ---------------------------------- */
/* FAQ */
/* ---------------------------------- */

.list-group-item.active {
  background-color: var(--primary-color) !important;
  border-color: #00acf0;
}
.list-group-item.active:hover {
  background-color: var(--primary-color) !important;
  border-color: #00acf0;
}

.list-group-item:hover {
  background-color: var(--secondary-color);
  border-color: #00acf0;
}
.accordion-button {
  font-weight: bold;
}
/* Page Hero */
/* .page-hero {
  background-image: 'url("/assets/inner-pagebg.jpg")';
  background-repeat: 'no-repeat';
  height: 370px;
  width: 100vw;
  background-position: 'center';
  background-size: 'cover';
} */
.page-hero::before {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
}
/* Terms and condition */
.terms-list {
  list-style-type: lower-roman;
}

/* Contact */
.contact-map.map {
  min-height: 20rem;
}
.cont-map .contact-form form {
  padding: 60px 30px;
  background: #fff;
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border: 10px solid #f7f7f7;
  border-radius: 5px;
}

.cont-map .contact-form form textarea {
  padding: 15px;
  background: #f7f7f7;
  border: 0;
  width: 100%;
}
.cont-map .contact-form form textarea {
  height: 160px;
}
/* Thumbnail */
.currentImage {
  border: 4px solid var(--primary-color);
}
.damages-cover-modal {
  margin: 0.5rem;
  /* max-width: calc(100% - 1rem); */
}

@media (min-width: 768px) {
  .damages-cover-modal {
    margin: 1.75rem auto;
  }
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
}

.mobile-tooltip {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-top: 1px solid rgba(0,0,0,.2);
  box-shadow: 0 -2px 10px rgba(0,0,0,.1);
  z-index: 1060;
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
}

.tooltip-wide {
  max-width: 300px;
}

.tooltip-inner {
  max-width: 300px;
  padding: 0;
  text-align: left;
  background-color: white;
  color: #000;
  border: 1px solid rgba(0,0,0,.2);
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgba(0,0,0,.2);
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }
  
  .table-responsive {
    margin: 0 -1rem;
    padding: 0 1rem;
    width: calc(100% + 2rem);
  }
}

.prev-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.upload-pen{
  position: absolute;
  bottom: 4px;
  right: 8px;
  border: 1.3px solid #fff;
  border-radius: 100px;
}
.next-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.transform-none{
  transform: none !important;
  margin-right: 90px !important;
}
/* property video */
.video-wrapper {
  min-height: 500px;
}
.video-wrapper .vid {
  position: relative;
  z-index: 8;
}
.video-wrapper .vid .vid-butn:hover .icon {
  color: #000;
}
.video-wrapper .vid .vid-butn:hover .icon:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.video-wrapper .vid .vid-butn .icon {
  color: #212529;
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 17px;
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.video-wrapper .vid .vid-butn .icon:after {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border: 1px solid #eee;
  border-radius: 50%;
  z-index: -1;
}
.video-wrapper .vid .vid-butn .icon:before {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}
/*  New Modal react responsive modal */
.customModal {
  width: 90%;
  max-width: 500px !important;
  border-radius: 20px;
}

@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}

/* message sidebar */
.hideMessage {
  transform: translateX(-100%);
}
.displayMessage {
  transform: translateX(0);
}
/* kldjdsakjfklasnjgnalkgnasdl */
.hero-title h1 {
  /* font-family: 'ClashDisplay-Variable' !important; */
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 39.36px;
}
.hero-title h2 {
  font-family: 'Onest', sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17.85px;
}

.star {
  color: #f9b501;
}

.hero-section {
  height: 406px;
}
.hero {
  bottom: -0.8rem;
}
.section-header h3 {
  font-size: 24px !important;
  font-weight: 600;
}
.flex{
  display: flex ;
}
.flex-col{
  flex-direction: column;
}
.items-center{
  align-items: center;
}
.items-start{
  align-items:start
}
.justify-between{
  justify-content: space-between;
}
.justify-center{
  justify-content: center;
}
.gap-10{
  gap: 10px;
}
.text-justify{
  text-align: justify;
}
.gap-20{
  gap: 2rem;
}
.sc-list-unstyled {
  list-style-type: disc; 
  padding-left: 1.5rem;
}
.sc-ist-unstyled li {
  position: relative;
  padding-left: 1.5rem; 
}
.gap-40{
  gap: 4rem;
}
.pt-8{
  padding-top: 40px;
}
.flex{
  display: flex ;
}
.flex-col{
  flex-direction: column;
}
.items-center{
  align-items: center;
}
.items-start{
  align-items:start
}
.justify-between{
  justify-content: space-between;
}
.justify-center{
  justify-content: center;
}
.gap-10{
  gap: 10px;

}
.gap-20{
  gap: 2rem;
}
.gap-40{
  gap: 4rem;
}
.pt-8{
  padding-top: 40px;
}

.popular-location {
  /* background: url('./assets/images/vi.jpeg'); */
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
    url('./assets/images/vi.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 574px;
}

.popular-location p {
  max-width: 274px;
}

.rounded-10{
  border-radius: 100px;
  padding: 6px 10px;
}
.rounded-4{
  border-radius: 30px;

}
.rounded-1{
  border-radius: 10px;
}
.overflow-x-auto{
  overflow-x: auto !important; 
}
.object-cover{
  object-fit: cover !important;
}
.rounded-50{
  border-radius: 50px;
}
.notif-card{
  background-color: #FAFAFA;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 40px 0;
}
.my-10{
margin: 40px 0;
}
.my-0{
  margin: 0 !important;
}
.metrics-card{
  background-color: #fafafa ;
  border-radius: 10px ;
  padding: 20px 15px;
  width: 100%;

}
.metrics-img{
  background-color: #DAF1EE;
  border-radius: 100%;
  width: 3rem;
  padding: 8px;
}
.metrics-grade{
  font-size: 12px;
  width:max-content;
  margin-left: auto;
}

.tabs-container {
  width: 100%;
}
.rounded-tabs-menu{
  border: 1px solid #d3d3d3;
  border-radius: 100px;
  padding: 5px;
  width: 70%;
  margin: auto;
}
.bottom-tab-menu{
  border-bottom: 1px solid #d3d3d3;
  padding-left: 0 !important;
  width: 100%;

}
.tabs-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabs-menu.single-tab {
  padding-left: 0.5rem;
  pointer-events: none;
}

.tab-item {
  height: 100%;
  color: #a0a0a0;
  width: 160px;
}

.tab-button {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
  justify-content: center;
}
.rounded-tab-button {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: #727272;
  cursor: pointer;
  justify-content: center;
}
.rounded-tab-button.active{
background-color: #47BBA9;
color: #fff;
border-radius: 100px;
padding: 10px 0;
}

.tab-button.active {
  color: #47BBA9;
  border-bottom: 4px solid #47BBA9;
}
.primary-border{
  border: 1.5px solid  var(--primary-color) ;
}

.tab-count {
  background-color: #b0acb6;
  color: white;
  font-size: 0.875rem;
  border-radius: 9999px;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
}

.tab-count.active {
  background-color: #392f49;
}

.tab-content {
  margin-top: 1rem;
}
.single-proj{
  border-radius: 15px;
  cursor: pointer;

}
.single-proj-img{
  height: 15rem;
  width: 100%;
}
.single-proj-body{
min-height:18rem
}
.m-0{
  margin: 0;
}
.p-0{
  padding: 0
}
.p-5{
  padding: 1rem;
}
.p-10{
  padding: 2rem;
}
.single-proj-body{
  height: 12rem;
}
.searchContainer {
  position: relative;
}
.searchIcon {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
}
.searchInput {
  width: 100%;
  padding: 8px 16px 8px 40px;
  border: 1px solid #D1D5DB;
  border-radius: 100px;
}
.rounded-b-15{
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px ;
}
.projects-div{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px 25px;
  margin: 40px 0;
}
.float-right{
  float: right;
}
.float-left{
  float: left;
}
.proj-img{
  width: 100%;
  height: 24rem; 
  min-width: 25rem;
  border-radius: 15px;
}
.ml-auto{
  margin-left: auto;
}
.previous{
  background-color: #FFEBEB;
  color: #FF0000;
}
.current{
  background-color: #EBFFF3;
  color: #21BF73;
}
.future{
  background-color: #FFFAEB;
  color: #FFC700;
}
.delete-btn{
  background-color: #fff;
  color: #FF0000;
  border: 2px solid #FF0000
}
.rounded-delete-btn{
  background-color: #FF0000;
  color: #fff;
  border: 1px solid #FF0000;
  border-radius: 200px;
  padding: 6px 12px;
  font-weight: 600;
}
.custom-select-container {
  position: relative;
}

.custom-select-header {
  border: 1px solid #ced4da;
  background-color: white;
  cursor: pointer;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected-tag {
  background-color: #DAF1EE;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.tag-remove-btn {
  background: none;
  border: 1px solid #727272 ;
  border-radius: 100px;
  color: #727272;
  font-size: 18px;
  line-height: 1;
  padding: 0 4px;
  cursor: pointer;
  margin-left: 5px;

}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ced4da;
  border-top: none;
  background-color: white;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.custom-select-option {
  padding: 10px;
  cursor: pointer;
}

.custom-select-option:hover {
  background-color: #f8f9fa;
}

.custom-select-option.selected {
  background-color: #DAF1EE;
}
.prev-btn{
  border: 1.5px solid #727272 ;
  background-color: #fff;
}

/*Toggle switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1.8px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #47BBA9;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.sale-interest-card{
  background-color: var(--off-white);
  margin: 20px 0;
  padding: 30px 40px;
  border-radius: 10px;
  cursor: pointer;
}

.sale-interest-card img{
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

/* Table.css */
.table-container {
  width: 100%;
  border: 1px solid #EEEEEE;
  border-radius: 10px ;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.table-responsive {
  position: relative;
  background-color: white;
  max-width: 100%;
  min-height: max-content;
  overflow-x: auto;
  min-width: 100%;
  border-radius: 0.5rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-radius: 0.75rem 0.75rem 0 0;
  overflow: auto;
  padding: 10px;
}

.table-header {
  font-size: 1rem;
  font-weight: 600;
}

.table-header-cell {
  padding: 1rem;
}

.table-header-content {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  padding: 10px 0;
}

.table-header-text {
  font-size: 0.875rem;
  font-weight: 500;
}

.table-sort-icon {
  display: flex;
  align-items: center;
}

.table-cell {
  padding: 1rem;
  min-width: 7.5rem;
  max-width: 30rem;
  border-bottom: 1px solid #EAECF0;
  font-size: 1rem;
  color: #333333;
}

.table-loading,
.table-empty-cell {
  text-align: center;
}

.table-empty {
  height: 30vh;
}

.table-row {
  cursor: pointer;
}

.table-action {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 180px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: -60%; 
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
}
.absolute{
  position: absolute;

}
.relative{
  position: relative;

}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}


/* Search.css */
.search-container {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search-input {
  border: 1px solid #D0CDD3;
  background-color: #F9FAFB;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%; 
  padding-left: 2.5rem;  
}

.search-input::placeholder {
  color: #888292;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #3B82F6; 
}

.search-input.has-value {
  padding-left: 0.75rem;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #888292;
}
/* SortButton.css */
.sort-button-container {
  position: relative;
}

.sort-button {
  border: 1px solid #D0D5DD;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  height: 100%;
}

.sort-icon {
  margin-right: 0.25rem;
}

.sort-dropdown {
  position: absolute;
  z-index: 10;
  margin-top: 0.25rem;
  width: max-content;
  background-color: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.sort-dropdown-header h3 {
  color: #1C1D22;
  font-weight: 500;
}

.sort-options-list {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.sort-option {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
}

.sort-option h5 {
  font-size: 0.875rem;
  color: #2B2F32;
}

/* FilterButton.css */
.filter-button-container {
  position: relative;
}

.filter-button {
  border: 1px solid #D0D5DD;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  height: 100%;
}

.filter-icon {
  margin-right: 0.25rem;
}

.filter-dropdown {
  position: absolute;
  z-index: 10;
  margin-top: 0.25rem;
  width: max-content;
  right: 2.5rem;
  background-color: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.filter-dropdown-header h3 {
  color: #1C1D22;
  font-weight: 500;
}

.filter-options-list {
  padding: 0.25rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.grid-cols-5{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

}
.grid-cols-3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

}
.filter-option {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #2B2F32;
  cursor: pointer;
}

.filter-option:hover {
  background-color: #F3F4F6;
}

.filter-label {
  display: flex;
  align-items: center;
}

.filter-checkbox {
  margin-right: 0.5rem;
}

.filter-actions {
  border-top: 1px solid #F0F1F5;
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.filter-apply-button {
  width: 100%;
  background-color: var(--primary-color, #007bff);
  color: white;
  border-radius: 0.75rem;
  padding: 0.5rem 0;
  font-size: 0.875rem;
}

.filter-reset-button {
  width: 100%;
  background-color: white;
  border: 1px solid #DDE1E6;
  color: #344054;
  border-radius: 0.75rem;
  padding: 0.5rem 0;
  font-size: 0.875rem;
}

/* Developer Dashboard screens */
.nav-links{
  color: #292929;
  text-decoration: none !important;
  display: flex;
  gap: 10px;
  padding: 15px 10px;
  margin: 15px 0;
  border-radius: 10px; 
  font-weight: 600;

}
.nav-links,
.nav-links:visited,
.nav-links:focus,
.nav-links:active {
  color: #292929;
  text-decoration:none
}
.border-black{
 border: 1px solid #292929
}
.nav-links:hover{
  color: #292929;
  text-decoration: none !important;
  background-color: #DAF1EE;
  border-radius: 10px; 
}

ul {
  list-style-type: none;
}

.dev-head{
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  border-bottom: 1px solid #EEEEEE;
  align-items: center;
  background-color: #fff;
  margin-top: 5rem;
}
.h-fit{
  height: fit-content;
}
.h-max{
  height: max-content;
}
.search-input{
  border: 1px solid #CCCCCC;
  border-radius: 100px;
}
.rounded-10{
  border-radius: 100px;
  padding: 6px 10px;
}
.rounded-4{
  border-radius: 30px;

}
.rounded-1{
  border-radius: 10px;
}
.overflow-x-auto{
  overflow-x: auto !important; 
}
.object-cover{
  object-fit: cover !important;
}
.rounded-50{
  border-radius: 50px;
}
.notif-card{
  background-color: #FAFAFA;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 40px 0;
}
.my-10{
margin: 40px 0;
}
.my-0{
  margin: 0 !important;
}
.metrics-card{
  background-color: #fafafa ;
  border-radius: 10px ;
  padding: 20px 15px;
  width: 100%;

}
.metrics-img{
  background-color: #DAF1EE;
  border-radius: 100%;
  width: 3rem;
  padding: 8px;
}
.metrics-grade{
  font-size: 12px;
  width:max-content;
  margin-left: auto;
}

.tabs-container {
  width: 100%;
}
.rounded-tabs-menu{
  border: 1px solid #d3d3d3;
  border-radius: 100px;
  padding: 5px;
  width: 70%;
  margin: auto;
}
.bottom-tab-menu{
  border-bottom: 1px solid #d3d3d3;
  padding-left: 0 !important;
  width: 100%;

}
.tabs-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabs-menu.single-tab {
  padding-left: 0.5rem;
  pointer-events: none;
}

.tab-item {
  height: 100%;
  color: #a0a0a0;
  width: 160px;
}

.tab-button {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
  justify-content: center;
}
.rounded-tab-button {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: #727272;
  cursor: pointer;
  justify-content: center;
}
.rounded-tab-button.active{
background-color: #47BBA9;
color: #fff;
border-radius: 100px;
padding: 10px 0;
}

.tab-button.active {
  color: #47BBA9;
  border-bottom: 4px solid #47BBA9;
}
.primary-border{
  border: 1.5px solid  var(--primary-color) ;
}

.tab-count {
  background-color: #b0acb6;
  color: white;
  font-size: 0.875rem;
  border-radius: 9999px;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
}

.tab-count.active {
  background-color: #392f49;
}

.tab-content {
  margin-top: 1rem;
}
.single-proj{
  border-radius: 15px;
  cursor: pointer;

}
.single-proj-img{
  height: 15rem;
  width: 100%;
}
.single-proj-body{
min-height:18rem
}
.m-0{
  margin: 0;
}
.p-0{
  padding: 0
}
.p-5{
  padding: 1rem;
}
.p-10{
  padding: 2rem;
}
.single-proj-body{
  height: 12rem;
}
.searchContainer {
  position: relative;
}
.searchIcon {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
}
.searchInput {
  width: 100%;
  padding: 8px 16px 8px 40px;
  border: 1px solid #D1D5DB;
  border-radius: 100px;
}
.rounded-b-15{
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px ;
}
.projects-div{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px 25px;
  margin: 40px 0;
}
.float-right{
  float: right;
}
.float-left{
  float: left;
}
.proj-img{
  width: 100%;
  height: 24rem; 
  min-width: 25rem;
  border-radius: 15px;
}
.ml-auto{
  margin-left: auto;
}
.previous{
  background-color: #FFEBEB;
  color: #FF0000;
}
.current{
  background-color: #EBFFF3;
  color: #21BF73;
}
.future{
  background-color: #FFFAEB;
  color: #FFC700;
}
.delete-btn{
  background-color: #fff;
  color: #FF0000;
  border: 2px solid #FF0000
}
.rounded-delete-btn{
  background-color: #FF0000;
  color: #fff;
  border: 1px solid #FF0000;
  border-radius: 200px;
  padding: 6px 12px;
  font-weight: 600;
}
.custom-select-container {
  position: relative;
}

.custom-select-header {
  border: 1px solid #ced4da;
  background-color: white;
  cursor: pointer;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected-tag {
  background-color: #DAF1EE;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.tag-remove-btn {
  background: none;
  border: 1px solid #727272 ;
  border-radius: 100px;
  color: #727272;
  font-size: 18px;
  line-height: 1;
  padding: 0 4px;
  cursor: pointer;
  margin-left: 5px;

}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ced4da;
  border-top: none;
  background-color: white;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.custom-select-option {
  padding: 10px;
  cursor: pointer;
}

.custom-select-option:hover {
  background-color: #f8f9fa;
}

.custom-select-option.selected {
  background-color: #DAF1EE;
}
.prev-btn{
  border: 1.5px solid #727272 ;
  background-color: #fff;
}

/*Toggle switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1.8px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #47BBA9;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.sale-interest-card{
  background-color: var(--off-white);
  margin: 20px 0;
  padding: 30px 40px;
  border-radius: 10px;
  cursor: pointer;
}

.sale-interest-card img{
  width: 100px;
  height: 100px;
  border-radius: 5px;
}




/* Developer Dashboard screens */
.nav-links{
  color: #292929;
  text-decoration: none !important;
  display: flex;
  gap: 10px;
  padding: 15px 10px;
  margin: 15px 0;
  border-radius: 10px; 
  font-weight: 600;

}
.nav-links,
.nav-links:visited,
.nav-links:focus,
.nav-links:active {
  color: #292929;
  text-decoration: none;
}

.nav-links:hover{
  color: #292929;
  text-decoration: none !important;
  background-color: #DAF1EE;
  border-radius: 10px; 
}

ul {
  list-style-type: none;
}

.dev-head{
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  border-bottom: 1px solid #EEEEEE;
  align-items: center;
  background-color: #fff;
}
.search-input{
  border: 1px solid #CCCCCC;
  border-radius: 100px;
}

.rounded-50{
  border-radius: 50px;
}
.notif-card{
  background-color: #FAFAFA;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 40px 0;
}
.my-10{
margin: 40px 0;
}
.metrics-card{
  background-color: #fafafa ;
  border-radius: 10px ;
  padding: 20px 15px;
  width: 100%;

}
.metrics-img{
  background-color: #DAF1EE;
  border-radius: 100%;
  width: 3rem;
  padding: 8px;
}
.metrics-grade{
  font-size: 12px;
  width:max-content;
  margin-left: auto;
  

}
.tabs-container {
  width: 100%;
}

.tabs-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d3d3d3;
  /* padding: 4px !important; */
}

.tabs-menu.single-tab {
  padding-left: 0.5rem;
  pointer-events: none;
}

.tab-item {
  height: 100%;
  color: #a0a0a0;
  width: 160px;
}

.tab-button {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
  justify-content: center;
}

.tab-button.active {
  color: #47BBA9;
  border-bottom: 4px solid #47BBA9;
}
.primary-border{
  border: 1.5px solid  var(--primary-color) ;
}

.tab-count {
  background-color: #b0acb6;
  color: white;
  font-size: 0.875rem;
  border-radius: 9999px;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
}

.tab-count.active {
  background-color: #392f49;
}

.tab-content {
  margin-top: 1rem;
}
.single-proj{
  border-radius: 15px;
}
.rounded-b-15{
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px ;
}
.projects-div{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 25px;
}
.float-right{
  float: right;
}
.proj-img{
  width: 100%; 
}
.ml-auto{
  margin-left: auto;
}
.previous{
  background-color: #FFEBEB;
  color: #FF0000;
}
.current{
  background-color: #EBFFF3;
  color: #21BF73;
}
.future{
  background-color: #FFFAEB;
  color: #FFC700;
}
.delete-btn{
  background-color: #fff;
  color: #FF0000;
  border: 2px solid #FF0000
}
.custom-select-container {
  position: relative;
}

.custom-select-header {
  border: 1px solid #ced4da;
  background-color: white;
  cursor: pointer;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected-tag {
  background-color: #DAF1EE;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.tag-remove-btn {
  background: none;
  border: 1px solid #727272 ;
  border-radius: 100px;
  color: #727272;
  font-size: 18px;
  line-height: 1;
  padding: 0 4px;
  cursor: pointer;
  margin-left: 5px;

}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ced4da;
  border-top: none;
  background-color: white;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.custom-select-option {
  padding: 10px;
  cursor: pointer;
}

.custom-select-option:hover {
  background-color: #f8f9fa;
}

.custom-select-option.selected {
  background-color: #DAF1EE;
}
.prev-btn{
  border: 1.5px solid #727272 ;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .popular-location {
    border-radius: 20px;
  }
  .popular-location .g-container {
    position: absolute !important;
    bottom: 2rem;
    left: 3rem;
  }
  .popular-location h4 {
    font-size: 3rem !important;
  }
  .popular-location p {
    font-size: 1rem !important;
    max-width: 494px;
  }
  .btn-outline-location {
    width: auto;
  }

}
@media screen and (min-width: 992px) {
  
  .hero-title h1 {
    /* font-family: 'ClashDisplay-Variable', serif !important; */
    font-style: normal;
    font-size: 48px !important;
    line-height: 58.2px;
  }
  .hero-title h2 {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 25.5px;
  }
 
}
@media screen and (min-width: 992px) {
  .hero-title h4 {
    font-family: 'Playfair Display', serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 58.2px;
  }
  .hero-title p {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 25.5px;
  }
}

@media screen and (min-width: 1020px) {
  .hero {
    bottom: 5rem;
  }
  .section-header h3 {
    font-size: 28px !important;
  }

  .popular-location .g-container {
    left: 5.375rem;
  }
  
}

@media screen and (min-width: 1200px) {
  .hero-title h4 {
    font-family: 'Playfair Display', serif !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 48px !important;
    line-height: 58.2px;
  }
  .hero-title p {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 25.5px;
  }
  .hero-section {
    height: 544px;
  }
  .hero {
    bottom: 5rem;
  }
  .projects-div{
    grid-template-columns: repeat(3, 1fr);
  
  }
  .dev-head{
  margin-top: 0;
  }
}
.service {
  background: url('./assets/images/service-bg.jpeg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: #fff !important;
  background-color: #47bba9;
  /* border-color: var(--bs-pagination-active-border-color); */
}

.accordion-button:not(.collapsed) {
  color: var(--primary-color);
  /* background: var(--bg-primary-color); */
  background-color: #edf8f6;
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0
    var(--bs-accordion-border-color);
}

.search-nav .nav-link:hover {
  transition: all 0.5s ease-in-out;
  color: var(--primary-color) !important;
}
/* modal test */
.slide-container {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease;
}

.slide {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slide.active {
  opacity: 1;
}

.slide:first-child {
  margin-right: auto;
}

.slide:last-child {
  margin-left: auto;
}
/* custom-styles.css */
.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active,
.custom-dropdown .dropdown-item:hover {
  background-color: #daf1ee !important;
}

/* Whatsapp Shake */

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-8px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(8px);
  }
}

.shake-animation {
  animation: shake 1.2s ease-in-out 3;
}
